import React, { Component } from 'react';
import { graphql } from 'gatsby';
import Layout from '../components/Layout';
import Link from '../components/Link';
import SEO from '../components/SEO';
import Icon from '../assets/images/blocks/block-1.svg';
import styled from 'styled-components';
import tw from 'twin.macro';
const MaxWidth = styled.div`
  ${tw`mx-auto`}
  max-width:724px;
`;
export default class Career extends Component {
  render() {
    const { wpCareer } = this.props.data;
    const {
      seo,
      title,
      content,
      // jobPage: { location },
    } = wpCareer;

    return (
      <Layout>
        <SEO seo={seo} />

        <section className="pad-t--xs">
          <div className="container">
            <MaxWidth>
              <img src={Icon} alt="Icon" style={{ maxWidth: '97px' }} />
              <div className="mt-12 mb-10 breadcrumbs font-bold flex uppercase">
                <div className="opacity-20 hover:underline">
                  <Link to="/about/#open-roles">Careers</Link>
                </div>
                <div className="opacity-20">&nbsp;&nbsp;/&nbsp;&nbsp;</div>
                <div className="">Job Listing</div>
              </div>
              <h1 className="text-58px font-semibold my-6">{title}</h1>
              {/* <p className="text-22px">{location}</p> */}
            </MaxWidth>
          </div>
        </section>
        <section className="pad-b pt-8">
          <div className="container">
            <MaxWidth>
              <div className="post-content" dangerouslySetInnerHTML={{ __html: content }} />
            </MaxWidth>
          </div>
        </section>
      </Layout>
    );
  }
}

export const query = graphql`
  query Career($id: String!) {
    wpCareer(id: { eq: $id }) {
      title
      content
      ...CareerSEO
      # jobPage {
      #   location
      # }
    }
  }
`;
